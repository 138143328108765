const colorFormat = {
  drag(element, bound, area) {
    let pos1 = 0
    let pos2 = 0
    let pos3 = 0
    let pos4 = 0
    if (document.getElementById(bound)) {
      document.getElementById(area).onmousedown = dragMouseDown
    } else {
      element.onmousedown = dragMouseDown
    }

    function dragMouseDown(e) {
      e = e || window.event
      e.preventDefault()
      pos3 = e.clientX
      pos4 = e.clientY
      document.onmouseup = closeDragElement
      document.onmousemove = elementDrag
    }

    function elementDrag(e) {
      e = e || window.event
      e.preventDefault()
      pos1 = pos3 - e.clientX
      pos2 = pos4 - e.clientY
      pos3 = e.clientX
      pos4 = e.clientY
      let map = document.getElementById('map-area')
      let boundWidth = document.getElementById(bound).offsetWidth
      let boundHeight = document.getElementById(bound).offsetHeight
      let left = element.offsetLeft
      let top = element.offsetTop
      if (element.offsetLeft < 0) left = 0
      if (element.offsetLeft > map.offsetWidth - boundWidth) left = map.offsetWidth - boundWidth
      if (element.offsetTop < 0) top = 0
      if (element.offsetTop > map.offsetHeight - boundHeight) top = map.offsetHeight - boundHeight
      element.style.top = top - pos2 + 'px'
      element.style.left = left - pos1 + 'px'
    }

    function closeDragElement() {
      document.onmouseup = null
      document.onmousemove = null
    }
  },
}
export default colorFormat
