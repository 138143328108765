<template>
  <div class="d-flex flex-column fill-height">
    <v-layout id="map-area" class="pb-2 overflow-hidden" style="flex: 1;position:relative;">
      <CardMapView
        ref="map"
        :removable="true"
        :drawing.sync="drawing"
        :legends="legends"
        :currentAOI="currentAOI"
        :layerRaster="layerRaster"
        :mapSync.sync="mapSync"
        :dataSyncMap="dataSyncMap"
      />
      <DataDetailDialog ref="dataDetailDialog" :currentData="currentData" :legends="legends" @zoomToFeature="zoomToFeature"/>
    </v-layout>
    <div class="data-area d-flex custom-card-bg-1 pa-2">
      <div class="actions">
        <fieldset>
          <legend>Data selection:</legend>
          <Select2
            v-model="currentAOI"
            :items="listAOI"
            :loading="loading"
            clearable
            dense
            hide-details
            item-text="name"
            item-value="uuid"
            label="Select AOI"
            outlined
            placeholder="AOI"
            return-object
            @change="getService"
          />
          <v-select
            v-model="source"
            :disabled="!service"
            :items="sources"
            :loading="loading"
            clearable
            dense
            hide-details
            item-text="name"
            label="Select source"
            outlined
            placeholder="Source"
            return-object
            @change="getResult"
          />
        </fieldset>
        <fieldset>
          <legend>Date range:</legend>
          <DatePicker v-model='filters.from_date' :max='filters.to_date'
            label='From' left
            outlined append-icon="mdi-calendar"/>
            <DatePicker v-model='filters.to_date' :min='filters.from_date'
            label='To' left
            outlined append-icon="mdi-calendar"/>
        </fieldset>
      </div>
      <div ref="timeChart" class="d-flex flex-column overflow-y-hidden ml-2" style="flex: 1; position: relative;">
        <div class="fill-height mb-2" style="flex: 1;">
          <SlideGroup :items="listData" :currentItem="currentData" multiple >
            <template v-slot:content="{ item, index }">
              <v-menu
                open-on-hover
                content-class="menu-card-detail"
                transition="slide-y-transition"
                top
                offset-y
                nudge-top="10"
                >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    v-on="on"
                    class="item-date custom-card-bg-1 d-flex align-center justify-center"
                    :class="{ selected: isSelectedData(item) }"
                    :key="index"
                    :id="index === 0 ? 'itemFirst' : index === listData.length - 1 ? 'itemLast' : ''"
                    @click="selectData(item)"
                  >
                    <div class="d-flex flex-column justify-center my-1" style="font-size: 12px; text-align: center;">
                      <span class="mb-1"> {{ item.customDate }} </span>
                      <div class="d-flex align-center justify-center">
                        <v-icon small :color="isSelectedData(item) ? '#1174ff' : ''">
                          {{ iconCardDate }}
                        </v-icon>
                        <span style="margin-left: 4px;">{{ item.count }}</span>
                        <v-btn-toggle v-if="mapSync" class="ml-2" dense mandatory v-model="item.sideDisplay">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn text x-small @click.stop="displayMap(item, 'left')" :value="'left'"
                                     v-bind="attrs" v-on="on" :style="{
                                'background-color': isDisplayMap(item, 'left') ? 'var(--v-info-base)' : '',
                              }"> L </v-btn>
                            </template>
                            <span>Left map</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn text x-small @click.stop="displayMap(item, 'right')" :value="'right'"
                                     v-bind="attrs" v-on="on" :style="{
                                'background-color': isDisplayMap(item, 'right') ? 'var(--v-info-base)' : '',
                              }"> R </v-btn>
                            </template>
                            <span>Right map</span>
                          </v-tooltip>
                        </v-btn-toggle>
                      </div>
                    </div>
                  </div>
                </template>

                <v-card class="card-detail d-flex flex-column align-center">
                  <v-card-title>Total: {{ item.count }}</v-card-title>
                  <v-card-text class="d-flex flex-column align-center">
                    <span style="text-align:center;">{{ item.date }}</span>
                    <a href="#" class="clickable-base" style="text-decoration:underline;" @click="openDialogDetail(item)">More details</a>
                  </v-card-text>
                </v-card>
              </v-menu>
            </template>
          </SlideGroup>
        </div>
        <v-card class="custom-card-bg-1 overflow-y-hidden" height="100%" width="100%">
          <ChartData ref="chartData" :onClick="onClickChart"/>
        </v-card>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from '@/store/ults'
import Select2 from '@/components/Select2/Select2.vue'
import DatePicker from '@/components/DatePicker.vue'
import CardMapView from './components/CardMapView.vue'
import DataDetailDialog from './components/DataDetailDialog.vue'
import { getOrder } from '@/api/order'
import SlideGroup from './components/SlideGroup.vue'
import ChartData from './components/ChartData.vue'
import { AIRCRAFT } from '@/constants/detection'
import { getLayer } from '@/api/detection-api'

export default {
  components: {
    Select2,
    DatePicker,
    DataDetailDialog,
    CardMapView,
    SlideGroup,
    ChartData
  },
  props: {
    legends: { type: Array, default: () => [] },
    titleAxisY: { type: String, default: '' },
    iconCardDate: { type: String, default: '' },
    getListData: { type: Function, default: () => {}},
    listData: { type: Array, default: () => []}
  },
  computed: {
    ...mapState('AOI', ['AOIs', 'listAOI']),
    customLegends() {
      return this.$store.getters['detection/customLegends']
    },
    result: {
      get() {
        return this.selectedResult
      },
      set(val) {
        this.$emit('update:selectedResult', val)
      },
    },
  },
  data() {
    return {
      AIRCRAFT,
      // props
      aoi: {},
      frequency: {},
      selectedService: {},
      selectedResult: {},
      selectedSource: {},
      // slider
      currentData: {},
      isMouseDown: false,
      //
      filters: {
        from_date: '',
        to_date: '',
      },
      currentAOI: {},
      source: undefined,
      sources: [],
      service: {},
      drawing: false,
      //
      loading: false,
      firstLoad: true,
      labels: [],
      layerRaster: {
        left: {},
        right: {}
      },
      toggleMapDisplay: 0,
      mapSync: false,
      dataSyncMap: {
        left: {},
        right: {}
      },
    }
  },
  methods: {
    isSelectedData(item) {
      return !this.mapSync
        ? (this.currentData && this.currentData.id === item.id)
        : ((this.dataSyncMap.right.id === item.id) || (this.dataSyncMap.left.id === item.id))
    },
    isDisplayMap(item, side) {
      return this.dataSyncMap[side].id === item.id
    },
    displayMap(item, side) {
      this.dataSyncMap[side] = item
    },
    async zoomToFeature(item) {
      const res = await this.$refs.map.getFeatureInfo(this.currentData.id, item.id)
      this.$refs.map.zoomToFeature(res.data.geojson, item)
    },
    openDialogDetail(month) {
      this.selectData(month)
      this.$refs.dataDetailDialog.openDialog(month)
    },
    selectData(data) {
      this.currentData = data
      if (this.mapSync) this.dataSyncMap.right = data
    },
    // get data
    async getListAOI() {
      try {
        // this.resetData()
        this.loading = true
        await this.$store.dispatch('AOI/getListAOI', {
          projectId: this.$route.params.id,
          payload: { service_name: this.$route.meta.service },
        })
        this.currentAOI = this.AOIs[0]
        // if (this.firstLoad) this.currentAOI = this.AOIs[0]
        // if (!this.currentAOI) this.firstLoad = false
      } catch (e) {
        console.log('Error when get list aoi: ', e)
      } finally {
        this.loading = false
      }
    },
    async getInfoAOI() {
      this.$refs.map.removeAllLayer()
      if (this.currentAOI) {
        this.$refs.map.zoomTo(this.currentAOI.bbox)
      }
    },
    async getService() {
      try {
        this.loading = true
        this.getInfoAOI()
        if (this.currentAOI) {
          const res = await getOrder({
            projectId: this.$route.params.id,
            id: this.currentAOI.uuid,
            payload: { service_name: this.$route.meta.service },
          })
          const services = res.data
          if (services.length > 0) {
            this.sources = []
            services.forEach(val => this.sources.push(val.image_source))
            this.source = this.sources[0]
            this.service = services[0]
            this.filters.from_date = this.service.from_date
            this.filters.to_date = this.service.to_date
            if (this.source) await this.getResult()
          }
        }
      } catch (e) {
        console.warn('Error when get service: ', e)
      } finally {
        this.loading = false
      }
    },
    async getResult() {
      try {
        if (!this.source) return (this.result = undefined)
        this.loading = true
        await this.getListData(this.source, this.filters, this.currentAOI)
      } catch (e) {
        console.warn('Error when get result: ', e)
      } finally {
        this.firstLoad = false
        this.loading = false
      }
    },
    setResult() {
      this.currentData = this.listData[0]
    },
    async getData(isLeft=false) {
      try {
        this.$refs.map.removeAllLayer(true, isLeft)
        const params = {
          projectId: this.$route.params.id,
          aoiId: this.currentAOI.uuid,
          vectorId: !this.mapSync ? this.currentData.id : isLeft ? this.dataSyncMap.left.id : this.dataSyncMap.right.id,
        }
        const res = await getLayer(params)
        this.layerRaster[isLeft ? 'left' : 'right'] = res.data[0]
        const _this = this
        res.data.forEach(layer => {
          _this.$refs.map.addToMap(layer, true, isLeft)
        })
      } catch(e) {
        console.warn('Error when get data: ', e)
      }
    },
    resetData() {
      this.source = undefined
      this.service = {}
      this.result = undefined
    },
    // chart
    onClickChart(c, i) {
      let e = i[0]
      if (!e) return
      let currentIndex = this.listData.find(data => data.date === this.$refs.chartData.labels[e.index])
      if (currentIndex) this.selectData(currentIndex)
    },
    makeChart() {
      const dataTotal = [];
      const dataCategories = {};

      // First loop: Populate dataTotal and dataCategories efficiently
      this.listData.forEach((data, index) => {
        dataTotal.push(data.count);
        const categories = data.categories;

        for (const key in categories) {
          if (!dataCategories[key]) {
            dataCategories[key] = Array(this.listData.length).fill(0);
          }
          dataCategories[key][index] = categories[key];
        }
      })

      // Create the data array
      const data = [
        {
          typeChart: 'line',
          type: 'Total',
          color: '#00ffff',
          data: dataTotal,
        },
        ...Object.entries(dataCategories).map(([key, values]) => ({
          typeChart: 'bar',
          type: key,
          color: this.customLegends[key],
          data: values,
          stack: true
        })),
      ]

      const chart = {
        label: this.listData.map(data => data.date),
        data
      }

      if (!this.$refs.chartData.chart) this.$refs.chartData.displayChart(chart, 'chartTime')
      else this.$refs.chartData.updateChartData(chart)
    },
  },
  async mounted() {
    await this.getListAOI()
  },
  watch: {
    filters: {
      handler() {
        if (!this.firstLoad) this.getResult()
      },
      deep: true
    },
    listData: {
      handler() {
        if (!this.mapSync) {
          this.setResult()
          this.makeChart()
        }
      },
      deep: true
    },
    'dataSyncMap.right': {
      handler() {
        this.getData()
      },
      deep: true
    },
    'dataSyncMap.left': {
      handler() {
        this.getData(true)
      },
      deep: true
    },
    currentData: {
      handler() {
        this.getData()
      },
      deep: true
    },
    mapSync(val) {
      if (val) {
        this.dataSyncMap.right = this.currentData
      } else {
        this.currentData = this.dataSyncMap.right
      }
    }
  },
}
</script>

<style scoped lang="scss">
$bgColorCardDetail: #1f1f49;

.data-area {
  height: 265px;
}
.actions {
  width: 220px;
}
.menu-card-detail {
  contain: none;
  overflow: visible;
  .card-detail {
    background-color: $bgColorCardDetail;
    padding: 0 8px !important;
  }
  &::before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    bottom: -19px;
    left: 16px;
    z-index: 1;
    border-width: 10px;
    border-style: solid;
    border-color: $bgColorCardDetail transparent transparent transparent;
  }
  &::after {
    content: "";
    width: 100%;
    height: 10px;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }
}
.item-date {
  cursor: pointer;
  position: relative;
  padding: 0 10px;
  margin: 0 2px;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  border-radius: 4px;
  border: 1px solid transparent;
  &.selected {
    color: var(--v-accent-base);
  }
  &:hover {
    border: 1px solid var(--v-primary-base);
  }
}
::v-deep {
  .v-btn:not(.v-btn--round).v-size--x-small {
    min-width: 22px !important;
  }
  .v-btn:not(.v-btn--round).v-size--x-small {
    height: 20px !important;
  }
  .v-slide-group__prev {
    display: flex;
  }
  .v-slide-group__next {
    display: flex;
  }
  .v-slide-group {
    scroll-behavior: smooth;
  }
  .v-card__text {
    padding: 2px 6px 6px !important;
  }
  .v-card .v-card__title {
    padding: 10px 0;
  }
}
legend {
  font-size: 1.3em;
}
</style>
