import L from 'leaflet'
import leafletImage from 'leaflet-image'

export const createMap = (idContainer, geojson, layerRaster, color = 'yellow') => {
  // Initialize the map
  const map = L.map(idContainer, {
    zoomControl: false,
    attributionControl: false,
  }).setView([51.505, -0.09], 2)

  // Add the raster tile layer
  L.tileLayer(layerRaster.tile_url, {
    maxZoom: 24,
    tileSize: 256,
    minZoom: layerRaster.min_zoom,
  }).addTo(map)

  // Add the GeoJSON layer with custom styling
  const geojsonLayer = L.geoJson(geojson, {
    style: {
      weight: 2,
      color,
      opacity: 1,
    },
    renderer: L.canvas()
  }).addTo(map)

  // Fit the map bounds to the GeoJSON layer
  map.fitBounds(geojsonLayer.getBounds(), { padding: [10, 10] })

  // change map to image
  map.whenReady(() => {
    leafletImage(map, (err, canvas) => {
      if (err) {
        console.error('Error capturing map image with leafletImage:', err)
        return
      }

      const mapContainer = document.getElementById(idContainer)
      mapContainer.innerHTML = ''
      mapContainer.appendChild(canvas)
    })
  })
}

