<template>
  <div v-show="dialog" id="areaBound">
    <v-card class="custom-card-bg-gradient">
      <v-card-title id="ard-area">
        <span>Data Statistics - {{ currentData.date }}</span>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text id="content-area">
        <div class="card-header d-flex align-center">
          <div class="card-total d-flex align-center flex-">
            <div class="pr-2" style="color: cyan !important; font-size: 1.5vw; font-weight: bold">
              Total: {{ currentData.count }}
            </div>
          </div>
          <v-spacer></v-spacer>
          <div class="actions">
            <v-text-field
              v-model="search"
              style="width: 100%;"
              append-icon="mdi-magnify"
              dense
              hide-details
              label="Search"
              placeholder="Search by name or type"
              outlined
              color="primary"
            ></v-text-field>
          </div>
        </div>
        <div class="d-flex">
          <div class="cards-detail d-flex flex-wrap">
            <div
              v-for="(category, ind) in categoriesFilter"
              :key="ind"
              class="card-item"
              @click="filterByCategory(ind)"
              :class="{ 'has-filter': filters.includes(ind) }"
            >
              <div class="custom-card-bg-1 d-flex flex-column align-center justify-center fill-height py-3" style="border-radius:5px;">
                <TooltipBase :title="ind" :triggerChange="dialog" :styles="{ color: customLegends[ind], width: '100px', textAlign: 'center' }" />
                <span style="font-size: 14px;">total: {{ category }}</span>
              </div>
            </div>
          </div>
          <div class="card-table">
            <v-data-table
              :headers="headers"
              :items="vectorDatasFilter"
              :items-per-page="5"
              :footer-props="{ disableItemsPerPage: true }"
              class="elevation-1"
            >
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="zoomToFeature(item)" icon small v-bind="attrs" v-on="on">
                      <v-icon>icon-gps</v-icon>
                    </v-btn>
                  </template>
                  <span>Zoom to</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import DragCP from "@/utils/DragComponent"
import TooltipBase from "@/components/TooltipBase.vue";

export default {
  components: {
    TooltipBase
  },
  props: {
    currentData: { type: Object, default: () => ({}) },
    legends: { type: Array, default: () => [] },
  },
  computed: {
    customLegends() {
      return this.$store.getters['detection/customLegends']
    },
    vectorDatasFilter() {
      return this.currentData?.vectorData?.filter(data => {
        if (this.filters.length > 0 && !this.filters.includes(data.type)) return false
        return data.name.toLowerCase().includes(this.search.toLowerCase()) || data.type.toLowerCase().includes(this.search.toLowerCase())
      })
    },
    categoriesFilter() {
      return this.currentData?.categories
    }
  },
  data() {
    return {
      dialog: false,
      headers: [
        { text: 'Id', value: 'id', sortable: false },
        { text: 'Name', value: 'name' },
        { text: 'Type', value: 'type' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      search: '',
      filters: []
    }
  },
  methods: {
    filterByCategory(category) {
      if (this.filters.includes(category)) {
        this.filters = this.filters.filter(item => item !== category)
      } else {
        this.filters.push(category)
      }
    },
    openDialog() {
      this.dialog = true
      let element = document.getElementById("areaBound")
      DragCP.drag(element, "areaBound", "ard-area")
    },
    zoomToFeature(item) {
      this.$emit('zoomToFeature', item)
    },
  },
}
</script>
<style scoped lang="scss">
$maxHeight: 420px;

#areaBound {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 700px;
  min-width: 700px;
  height: 500px;
  padding: 10px;
  z-index: 9999;
  #ard-area {
    cursor: move;
    padding: 10px !important;
  }
  #content-area {
    padding: 10px !important;
    max-height: $maxHeight;
    overflow: hidden;
  }
}
.card-total {
  border-radius: 5px;
  margin: 10px 0;
  span {
    font-size: 16px;
    font-weight: 500;
  }
}
.cards-detail {
  align-content: flex-start;
  gap: 4px;
  max-height: $maxHeight;
  overflow-y: auto;
  width: 300px;
  .card-item {
    width: calc(50% - 4px);
    height: 60px;
    &:hover {
      cursor: pointer;
      background-color: #232942;
      border-radius: 5px;
    }
    &.has-filter {
      cursor: pointer;
      background-color: #474955;
      border-radius: 5px;
    }
  }
}
.card-table {
  max-height: $maxHeight;
  overflow-y: hidden;
  padding-bottom: 60px;
}
</style>
