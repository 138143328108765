<template>
  <canvas id="chartTime" :height="heightChart" :width="widthChart"></canvas>
</template>
<script>
import Chart from 'chart.js/auto'
import zoomPlugin from 'chartjs-plugin-zoom'

export default {
  props: {
    widthChart: { type: String, default: '100%' },
    heightChart: { type: String, default: '200px' },
    typeChart: { type: String, default: 'line' },
    onClick: { type: Function, default: () => {} },
  },
  data() {
    return {
      chart: null,
      labels: [],
      chartData: [],
      optionsChart: {
        onClick: this.onClick,
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
          },
          zoom: {
            pan: {
              enabled: true,
              mode: 'x', // or 'y' or 'xy'
              threshold: 10
            },
            zoom: {
              wheel: {
                enabled: true
              },
              pinch: {
                enabled: true
              },
              mode: 'x', // or 'y' or 'xy'
            }
          }
        },
        title: {
          color: '#d4d4d4',
          display: true,
          text: '',
        },
        scales: {
          x: {
            ticks: {
              color: '#d4d4d4',
            },
            stacked: true,
            grid: {
              color: '#2F3241',
              display: true,
            },
          },
          y: {
            ticks: {
              color: '#d4d4d4',
              stepSize: 1,
            },
            stacked: true,
            title: {
              color: '#d4d4d4',
              text: this.titleAxisY,
              display: true,
            },
            grid: {
              color: '#2F3241',
              display: true,
            },
          },
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem) {
              return tooltipItem.yLabel
            },
          },
        },
        elements: {
          point: {
            radius: 4,
            hoverRadius: 6
          },
        }
      },
    }
  },
  methods: {
    transformData(chartInfo, type=this.typeChart) {
      this.chartData = []
      this.labels = []
      this.labels = chartInfo.label
      // TODO: make special point alert
      chartInfo.data.forEach(item => {
        if (item.typeChart == 'bar') {
          this.chartData.push({
            type: item.typeChart || type,
            label: item.type,
            data: item.data,
            backgroundColor: item.color,
            borderColor: item.color,
            stack: item.stack,
            fill: item.color,
          })
        } else {
          this.chartData.push({
            type: item.typeChart || type,
            label: item.type,
            data: item.data,
            backgroundColor: item.color,
            borderColor: item.color,
            borderWidth: 2,
            pointBorderWidth: 0,
            pointHitRadius: 0,
            pointBackgroundColor: item.color,
            stack: item.stack,
          })
        }
      })
    },
    displayChart(chartInfo, chartId) {
      this.transformData(chartInfo)
      const data = {
        labels: this.labels,
        datasets: this.chartData,
      }
      Chart.register(zoomPlugin)
      let ctx = document.getElementById(chartId)
      this.chart = new Chart(ctx, {
        data,
        options: this.optionsChart
      })
    },
    updateChartData(chartInfo) {
      this.transformData(chartInfo)
      this.chart.data.datasets = this.chartData
      this.chart.data.labels = this.labels
      this.chart.update()
    },
  }
}
</script>
