<template>
  <v-card class="custom-card-bg-1" height="100%" width="100%">
    <Map
      ref="map"
      :currentLayers="layers"
      :drawType.sync="drawType"
      :inspect.sync="inspect"
      :isDraw.sync="isDraw"
      :isCreateText="displayType === 'text'"
      @updateFeatureHandle="feature => $emit('updateFeatureHandle', feature)"
    />
    <div class="actions-top-left-corner">
      <v-tooltip color="#000000c4" right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="elevation-0 mb-1"
            :color="isMapSync ? 'primary' : 'bgContainer'"
            height="38"
            width="38"
            min-height="0"
            min-width="0"
            v-bind="attrs"
            v-on="on"
            small
            @click="isMapSync = !isMapSync"
          >
            <v-icon :color="isMapSync ? '#fff' : 'secondary'" size="22">{{ !isMapSync ? 'mdi-compare' : 'mdi-compare-remove' }}</v-icon>
          </v-btn>
        </template>
        <span>{{ !isMapSync ? 'Sync map' : 'Remove sync map' }}</span>
      </v-tooltip>
    </div>
    <div class="data-sync-map left-map" v-if="isMapSync && dataSyncMap.left">
      {{ dataSyncMap.left.customDate }}
    </div>
    <div class="data-sync-map right-map" v-if="isMapSync && dataSyncMap.right">
      {{ dataSyncMap.right.customDate }}
    </div>
    <div id="legend-object-detection" class="legend-map custom-card-bg-2" :style="{ left: displayLayerControl ? `${(widthLayerControl + leftPadding * 2)}px` : `${leftPadding}px` }">
      <div class="legend-items" ref="legendItems">
        <div v-for="(legend, ind) in legends" :key="ind" class="d-flex align-center">
          <div class="legend-color" :style="{ borderColor: legend.color }"></div>
          <div class="legend-text">{{ legend.value }}</div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapState } from '@/store/ults'
import Map from '@/components/Map.vue'
import VectorStyle from '@/utils/VectorStyle'
import utils from '@/utils/genUUID'
import mapboxgl from 'mapbox-gl/dist/mapbox-gl'
import { createMap } from '@/services/map/leaflet'
import { getFeature } from '@/api/change-detection-api'
import turf from 'turf'

export default {
  name: 'CardMapView',
  components: {
    Map
  },
  props: {
    inspect: { type: Boolean, default: false },
    showLayerControl: { type: Boolean, default: false },
    removable: { type: Boolean, default: false },
    drawing: { type: Boolean, default: false },
    legends: { type: Array, default: () => [] },
    currentAOI: { type: Object, default: () => ({}) },
    layerRaster: { type: Object, default: () => ({}) },
    mapSync: { type: Boolean, default: false },
    dataSyncMap: { type: Object, default: () => ({left: '', right: ''}) },
  },
  computed: {
    ...mapState('detection', ['typeLegends']),
    isDraw: {
      get() {
        return this.drawing
      },
      set(val) {
        this.$emit('update:drawing', val)
      }
    },
    isMapSync: {
      get() {
        return this.mapSync
      },
      set(val) {
        this.$refs.map.mapSync = val
        this.$emit('update:mapSync', val)
      }
    }
  },
  data() {
    return {
      // style
      widthLayerControl: 300,
      leftPadding: 10,
      //
      layerName: '',
      displayType: '',
      drawType: null,
      displayLayerControl: this.showLayerControl,
      layers: [],
      customLayerId: 'fake-layer-transparent',
      markerCentroidId: '',
      marker: new mapboxgl.Marker()
    }
  },
  methods: {
    drawAOI(AOI) {
      this.$refs.map.drawAOI(AOI)
    },
    setLayer(name, geojson) {
      this.$refs.map.addGeoJsonToMap(name, geojson, '#e2ed09', utils.getUUID(), 'line', true)
    },
    saveLayer() {
      let features = this.$refs.map.getAllDrawFeature()
      let vectorType
      switch (this.displayType) {
        case 'polygon':
        case 'line':
          vectorType = 'line'
          break
        case 'point':
          vectorType = 'circle'
          break
      }
      if (this.displayType === 'maker') {
        features.forEach(feature => {
          feature.properties = {}
          for (let i = 0; i < feature.geometry.coordinates.length; i++) {
            feature.geometry.coordinates[i] = feature.geometry.coordinates[i].toFixed(4)
          }
          feature.properties.text = feature.geometry.coordinates.toString().replaceAll(',', ', ')
        })
        let featureCollection = {
          type: 'FeatureCollection',
          features: features,
        }
        this.$refs.map.addMarkerSymbolToMap(this.layerName, featureCollection, 'yellow')
      } else if (this.displayType === 'text') {
        let featureCollection = {
          type: 'FeatureCollection',
          features: features,
        }
        this.$refs.map.addTextToMap(this.layerName, featureCollection, 'yellow')
      } else {
        features.forEach(feature => {
          feature.properties = {}
          feature.properties.text = feature.geometry.coordinates.toString()
        })
        let featureCollection = {
          type: 'FeatureCollection',
          features: features,
        }
        this.$refs.map.addGeoJsonToMap(this.layerName, featureCollection, 'yellow', utils.getUUID(), vectorType, true)
      }
      this.drawType = undefined
      this.layerName = ''
      this.$refs.map.deleteAllDraw()
      this.isDraw = false
    },
    createLayer(name, type) {
      this.displayType = type
      this.layerName = name
      switch (type) {
        case 'polygon':
        case 'line':
          this.drawType = 'polygon'
          break
        case 'maker':
        case 'text':
          this.drawType = 'point'
          break
      }
      this.isDraw = true
    },
    addToMap(val, isSync=true, isLeft=false) {
      if (val.tile_info.type === 'images') {
        let image = {
          id: val.name,
          display: true,
          data: val.tile_info,
          layerType: 'Raster_Image',
          name: val.name,
          tiles: [val.tile_info.tile_url],
          bounds: val.tile_info.bbox,
        }
        this.$refs.map.addImageToMap(image, undefined, isSync, isLeft)
        this.zoomTo(val.tile_info.bbox)
      } else if (val.tile_info.type === 'aoi') {
        this.$refs.map.addGeoJsonToMap(val.name, val.geojson, '#ff0015', utils.getUUID(), 'line', true)
      } else {
        const vector = {
          display: true,
          data: val.tile_info,
          tiles: [val.tile_info.tile_url],
          bounds: val.tile_info.bbox,
          paint: VectorStyle.getStyle(val.tile_info.styles),
          layerName: 'default',
          name: val.name,
          id: val.name,
          type: val.tile_info.styles.type,
        }
        this.$refs.map.addVectorTiles(vector, isSync, isLeft)

        this.makeCustomLayer(val, isSync, isLeft)
      }
    },
    makeCustomLayer(val, isSync, isLeft) {
      // add fake layer
      const customStyle = val.tile_info.styles
      const customLayerId = this.customLayerId + utils.getUUID()
      customStyle.type = 'fill'
      customStyle['opacity'] = 0
      this.$refs.map.addVectorTiles({
        isHover: true,
        display: true,
        data: val.tile_info,
        tiles: [val.tile_info.tile_url],
        bounds: val.tile_info.bbox,
        paint: VectorStyle.getStyle(customStyle),
        layerName: 'default',
        name: val.name,
        id: val.name,
        type: val.tile_info.styles.type,
      }, isSync, isLeft, customLayerId)

      this.makeCustomPopup(customLayerId, isLeft)
    },
    async getFeatureInfo(vectorId, featureId) {
      // get data
      const params = {
        projectId: this.$route.params.id,
        id: this.currentAOI.uuid,
        vectorId,
        featureId,
      }
      const res = await getFeature(params)
      res.data.geojson = {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {},
            geometry: res.data.geojson,
          },
        ],
      }
      return res
    },
    makeCustomPopup(customLayerId, isLeft=false) {
      const map = !isLeft ? this.$refs.map.map : this.$refs.map.syncMap
      map.on('click', customLayerId, async (e) => {
        e.preventDefault()
        // custom position
        const offset = 50
        const popupOffsets = {
            'top': [0, offset],
            'top-left': [offset, offset],
            'top-right': [-offset, offset],
            'bottom': [0, -offset],
            'bottom-left': [offset, -offset],
            'bottom-right': [-offset, -offset],
            'left': [offset, 0],
            'right': [-offset, 0]
        }

        let features = map.queryRenderedFeatures(e.point)
        const layerId = `popup-layer-${utils.getUUID()}`
        const color = this.legends.find(legend => legend.value === features[0].properties.type).color
        const popupContent = `
        <div style="display:flex;gap:4px;padding-right:4px;align-items:center;border: 2px solid ${color};">
          <div id="${layerId}" style="width: 200px;height:200px;"></div>
          <table>
            <tr>
              <th style="padding-right:4px;">Identify</th>
              <td>${features[0].properties?.name}</td>
            </tr>
            <tr>
              <th style="padding-right:4px;">Class</th>
              <td>${features[0].properties?.type}</td>
            </tr>
            <tr>
              <th style="padding-right:4px;">Length</th>
              <td>${Math.round(features[0].properties?.length * 100) / 100} m</td>
            </tr>
            <tr>
              <th style="padding-right:4px;">Width</th>
              <td>${Math.round(features[0].properties?.width * 100) / 100} m</td>
            </tr>
            <tr>
              <th style="padding-right:4px;">Area</th>
              <td>${Math.round(features[0].properties?.area * 100) / 100} m<sup>2</sup></td>
            </tr>
            <tr>
              <th style="padding-right:4px;">Perimeter</th>
              <td>${Math.round(features[0].properties?.perimeter * 100) / 100} m</td>
            </tr>
          </table>
        </div>
        `

        // Create the popup and set the content to the mounted component
        new mapboxgl.Popup({
            offset: popupOffsets,
            className: layerId,
            closeOnClick: false
          })
          .setLngLat(e.lngLat)
          .setHTML(popupContent)
          .addTo(map)

        const res = await this.getFeatureInfo(features[0].properties.vector_uuid, features[0].properties.id)

        this.$nextTick(() => {
          const popupElement = document.querySelector(`.mapboxgl-popup.${layerId}`)
          popupElement.style.borderColor = color
          createMap(layerId, res.data.geojson, this.layerRaster[isLeft ? 'left' : 'right'].tile_info, color)
        })

      })
    },
    reSize() {
      this.$refs.map.reSize()
    },
    removeAllLayer(isSync=true, isLeft=false) {
      this.$refs.map.removeAllLayer(isSync, isLeft)
    },
    zoomTo(bbox, padding=20, isBbox=true) {
      if (!isBbox) {
        bbox = turf.bbox(bbox)
      }
      this.$refs.map.submitZoom(bbox, padding)
    },
    zoomToFeature(geojson) {
      this.zoomTo(geojson, 200, false)
      const centroid = turf.centroid(geojson)
      this.marker.setLngLat(centroid.geometry.coordinates).addTo(this.$refs.map.map)
    },
    initColumnLegend() {
      const elementsInColumn = 10
      this.$refs.legendItems.style.gridTemplateColumns = `repeat(${Math.ceil(this.legends.length / elementsInColumn)}, 1fr)`
    },
  },
  watch: {
    legends() {
      this.initColumnLegend()
    }
  },
  mounted() {
    this.initColumnLegend()
  }
}
</script>

<style scoped lang="scss">
$offset: 50px;

::v-deep {
  table, td, th {
    border: 1px solid;
    border-collapse:collapse;
    color: black;
  }
  td, th {
    padding: 4px;
  }
  .mapboxgl-popup {
    &::before {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-color: inherit;
      //border-color: var(--pseudo-border-popup-color);
    }
    &.mapboxgl-popup-anchor-bottom::before {
      bottom: -50px;
      left: 50%;
      transform: translateX(-50%);
      border-width: $offset 1px $offset 1px;
    }
    &.mapboxgl-popup-anchor-top::before {
      top: -50px;
      left: 50%;
      transform: translateX(-50%);
      border-width: $offset 1px $offset 1px;
    }
    &.mapboxgl-popup-anchor-right::before {
      top: 50%;
      right: -50px;
      transform: translateY(-50%);
      border-width: 1px $offset 1px $offset;
    }
    &.mapboxgl-popup-anchor-left::before {
      top: 50%;
      left: -50px;
      transform: translateY(-50%);
      border-width: 1px $offset 1px $offset;
    }
    &.mapboxgl-popup-anchor-top-right::before {
      top: 0;
      right: 0;
      transform: rotateZ(-45deg) translate(55%, 35px);
      border-width: 1px $offset 1px $offset;
    }
    &.mapboxgl-popup-anchor-top-left::before {
      top: 0;
      left: 0;
      transform: rotateZ(45deg) translate(-55%, 35px);
      border-width: 1px $offset 1px $offset;
    }
    &.mapboxgl-popup-anchor-bottom-right::before {
      bottom: 0;
      right: 0;
      transform: rotateZ(45deg) translate(55%, -35px);
      border-width: 1px $offset 1px $offset;
    }
    &.mapboxgl-popup-anchor-bottom-left::before {
      bottom: 0;
      left: 0;
      transform: rotateZ(-45deg) translate(-55%, -35px);
      border-width: 1px $offset 1px $offset;
    }
  }
  .mapboxgl-popup-content {
    padding: 0;
  }
  .mapboxgl-popup-tip {
    display: none;
  }
  .mapboxgl-popup-close-button {
    z-index: 401; // for front- leaflet
  }
  .mapboxgl-popup {
    max-width: 500px !important;
  }
}
$widthLayerControl: 300px;
$leftPadding: 10px;
$bottomPadding: 10px;

.layer-control {
  width: $widthLayerControl;
  height: 95%;
  position: absolute;
  bottom: $bottomPadding;
  left: $leftPadding;
  z-index: 2;
}
.actions-top-left-corner {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
}
.data-sync-map {
  position: absolute;
  top: 10px;
  z-index: 1;
  background-color: var(--v-primary-base);
  border-radius: 10px;
  padding: 0 10px;
  color: white;
  &.left-map {
    left: 25%;
    transform: translateX(-50%);
  }
  &.right-map {
    right: 25%;
    transform: translateX(50%);
  }
}
.legend-map {
  position: absolute;
  bottom: $bottomPadding;
  z-index: 1;
  width: auto;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  padding: 10px 10px 10px 2px;
  .legend-title {
    font-weight: 600;
    font-size: 16px;
    text-align: center;
  }
  .legend-items {
    max-height: 200px;
    display: grid;
    grid-template-columns: 1fr;
    .legend-color {
      border: 1px solid;
      width: 20px;
      height: 0;
      margin: 0 6px;
    }
  }
}
</style>
