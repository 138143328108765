export default class ObjectModel {
  constructor(data) {
    this._id = data.id
    this._acquiredDate = data.acquired_date
    this._date = data.date
    this._vectorData = data.vector_data
  }

  get id() {
    return this._id
  }

  get date() {
    return this._date
  }

  get count() {
    return this._vectorData.length
  }

  get vectorData() {
    return this._vectorData
  }

  get customDate() {
    const date = new Date(this._date)
    const formatter = new Intl.DateTimeFormat('en-US', { month: 'short', day: 'numeric', year: 'numeric' })
    return formatter.format(date)
  }

  get categories() {
    const categories = {}
    this._vectorData.forEach(vector => {
      if (categories.hasOwnProperty(vector.type)) {
        categories[vector.type]++
      } else {
        categories[vector.type] = 1
      }
    })
    return categories
  }
}
